import * as React from 'react'
import SEO from '../components/SEO'
import Team from '../components/Team'
import talinni from '../images/uploads/talinni.png'
import till_carlos from '../images/people/till-carlos_bigcircle.png'
import scroll_mouse from '../images/uploads/Scroll mouse.png'
import biliyana from '../images/people/biliyana.png'
import Values from '../components/Values'
import Link from '../components/Link'
import bgPattern from '.././images/uploads/aboutBottomPattern.svg'
import arrow from '.././images/uploads/bottom_arrow.svg'

const AboutPage = () => {
  return (
    <>
      <SEO title="About Pairing" description="Reliability in Outsourcing" />
      <div className="flex flex-col items-center justify-center px-4 md:px-0 py-12 lg:py-24 text-center relative">
        <div className="max-w-4xl  flex flex-col items-center z-10">
          <h1 className="typo-h2 md:text-6xl font-bold ">
            Reliabili
            <span className="underline-highlight">ty in Outsourcing</span>
          </h1>
          <p className="font-display font-semibold text-base xl:text-lg opacity-80 mt-2 mb-11 p-0">
            Meet the team and learn what Pairing is about
          </p>

          <Link
            className="font-display text-2xl font-medium py-4"
            variant="primary"
            to="/join-standup"
            type="btn"
            size="normal"
          >
            Meet our Teams
          </Link>

          <div className="pt-12">
            <img
              src={scroll_mouse}
              className="w-10  text-center"
              alt="Scroll Mouse"
            />
          </div>
        </div>
        <div
          className="hidden md:block absolute w-full -top-20 bg-gradient-to-t from-white via-blue-50 dark:via-gray-700 to-white"
          style={{ zIndex: '-1' }}
        >
          <img src={talinni} alt="Talinni" className="" />
        </div>
      </div>
      <section className="main-section ">
        <h1 className="typo-h2 font-semibold max-w-lg text-center pb-12 pt-4">
          We started coding since we were teenagers
        </h1>
        <div className="w-full">
          <Team />
        </div>
      </section>

      <section className="row-section justify-center -mt-40 mx-0 mb-12 relative">
        <img src={bgPattern} alt="pattern" srcSet="" className="absolute" />
        <h1 className="typo-h2 font-semibold max-w-lg text-center py-12 ">
          We are fully <span className="underline-highlight">remote, b</span>y
          heart and by choice.
        </h1>
        {/* <img src={quation2} alt="" srcSet="" /> */}
      </section>
      <section className="main-section relative space-y-12 mx-0">
        {/* <div className="absolute h-12 -top-12 w-full bg-gradient-to-b from-white dark:from-dark_bg-normal  to-backgrd dark:to-dark_bg-lighter "></div> */}

        <div className="row-section  max-w-5xl">
          <div className="text-center text-blue-400 font-bold">
            <img src={till_carlos} className="my-2" alt="Till" />
            Dipl.-Inf. Till Carlos
          </div>
          <div className="md:ml-16">
            <div className="flex flex-col max-w-lg mt-6">
              <h1 className="typo-h2 font-semibold ">
                Meet the{' '}
                <span className="underline-highlight"> Founder &nbsp;</span>
              </h1>
              <p className="text-base py-1">
                How Till worked as a freelancer for German + US clients
              </p>
            </div>
            <ul className="list-none font-display font-light font-base leading-relaxed">
              <li className="my-4  flex before:content-['\2022'] before:mr-4 before:block before:text-teal-neutral -ml-4 before:text-4xl before:leading-7">
                Till co-founded a business in Germany called empuxa.com, that
                built custom software.
              </li>
              <li className="my-4 flex before:content-['\2022'] before:mr-4 before:block before:text-teal-neutral -ml-4 before:text-4xl before:leading-7">
                In 2013 he left Germany to live in Asia. He started freelancing
                as a Ruby on Rails Senior developer.
              </li>
              <li className="my-4 flex before:content-['\2022'] before:mr-4 before:block before:text-teal-neutral -ml-4 before:text-4xl before:leading-7">
                After seeing that many companies struggle with finding talent,
                he focused his company fully on building full teams.
              </li>
              <li className="my-4 flex before:content-['\2022'] before:mr-4 before:block before:text-teal-neutral -ml-4 before:text-4xl before:leading-7">
                Till wrote his thesis at XING AG and is in the software business
                for 20 years now.
              </li>
            </ul>
            <Link
              to="/open-processes"
              className="text-primary dark:text-blue-300 underline"
            >
              Read the company's blog under The Business of Software
            </Link>
          </div>
        </div>

        <div className="row-section max-w-5xl ">
          <div className="md:mr-16">
            <div className=" py-8">
              <h1 className="typo-h2 text-text_gray  ">
                Teams need
                <span className="underline-highlight">
                  {' '}
                  structure &nbsp;{' '}
                </span>{' '}
              </h1>
              <Link
                to="/open-processes/how-we-manage"
                className="text-primary dark:text-blue-300 underline"
              >
                How we manage teams
              </Link>
            </div>
            <ul className="list-none  font-display font-light font-base leading-relaxed">
              <li className="my-4 flex before:content-['\2022'] before:mr-4 before:block before:text-teal-neutral -ml-4 before:text-4xl before:leading-7">
                Bilyana joined with the first team and grew the company since
                then.
              </li>
              <li className="my-4 flex before:content-['\2022'] before:mr-4 before:block before:text-teal-neutral -ml-4 before:text-4xl before:leading-7">
                We are following a clear Scrum process, with all our teams.
              </li>
              <li className="my-4 flex before:content-['\2022'] before:mr-4 before:block before:text-teal-neutral -ml-4 before:text-4xl before:leading-7">
                She is the first Senior Project Manager at Pairing and now
                oversees the hiring and staffing processes.
              </li>
            </ul>
          </div>
          <div className="font-bold text-blue-400 text-center">
            <img src={biliyana} className="my-2" alt="Biliyana" />
            Bilyana Vacheva, ex. Senior Project Manager, HR Manager
          </div>
        </div>
      </section>
      <section className="row-section sm:mx-0 justify-center relative ">
        {/* <img src={quation} alt="" srcSet="" /> */}
        <img src={bgPattern} alt="pattern" srcSet="" className="absolute" />

        <div className="text-center py-12 max-w-2xl ">
          <h1 className="typo-h2 font-semibold text-center">
            Openness and
            <span className="underline-highlight"> Sharing</span>
          </h1>
          <p>
            There was a point in time where we needed outsourced teams
            ourselves. So we went ahead and searched for companies to help us.
          </p>
        </div>
        {/* <img src={quation2} alt="" srcSet="" /> */}
      </section>
      <section className="main-section relative py-12">
        <h1 className="typo-h2 text-text_gray text-center max-w-5xl z-10 underline-highlight">
          We wanted
        </h1>
        <p className="font-display font-light font-base max-w-2xl text-center">
          A lot of smart Engineers starting staffing companies, but all of them
          lacked a unified process and transparency.
        </p>
        <p className="font-display text-center font-light font-base ">
          So we built our company to address these points.
        </p>
      </section>

      <section className="main-section">
        <h1 className="typo-h2 underline-highlight text-text_gray">Values</h1>
        <div className="py-12">
          <Values />
        </div>
      </section>

      {/* <section className="main-section">
        <img src={future} className="py-12" alt="" />
      </section> */}

      <section className="main-section py-0 md:py-8 px-4 ">
        <h1 className="typo-h2 text-text_gray text-center">
          In the future: working with{' '}
          <span className="underline-highlight uppercase">you</span> is awesome
        </h1>
        <div className="flex flex-col max-w-3xl items-center relative">
          <p className="typo-small pb-0 text-2xl text-center">
            Is Pairing a company that resonates with you?
          </p>
          <img
            src={arrow}
            alt="arrow"
            className="absolute right-0 top-16 sm:-right-16 sm:top-10"
          />
          <Link
            className="my-8 w-80 py-5 text-center"
            variant="primary"
            to="/book"
            type="btn"
            size="extra"
          >
            Talk to a PM
          </Link>
        </div>
      </section>
    </>
  )
}

export default AboutPage
