import classnames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'
import PropTypes from '../../utils/PropTypes'
import CustomImage from '../CustomImage/index'
import Button from '../../components/Button'

const Team = ({ className /* , container */ }) => {
  const [teams, setTeam] = React.useState([])
  const [more, setMore] = React.useState(true)
  const data = useStaticQuery(graphql`
    query {
      teams: allTeamYaml(limit: 99) {
        nodes {
          id
          name
          description
          linkedin
          image {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1.5)
            }
          }
        }
      }
    }
  `)
  const loadMore = () => {
    if (more) {
      setTeam(data.teams.nodes)
    } else {
      let datas = data.teams.nodes
      setTeam(datas.slice(0, 4))
    }
    setMore(!more)
  }

  React.useEffect(() => {
    let datas = data.teams.nodes
    setTeam(datas.slice(0, 99))
  }, [data.teams.nodes])
  return (
    <div className={classnames('', className)}>
      <div className="flex flex-wrap justify-center items-center w-full gap-3 md:gap-0">
        {teams.map((item, index) => (
          <div
            key={index}
            className="relative flex flex-col items-center justify-center flex-wrap w-5/11 sm:w-auto mx-0 md:mx-6"
          >
            <CustomImage
              className="my-4 w-full sm:w-56 h-44 sm:h-64 mx-0 bg-blue-200 rounded-xl  "
              image={item.image}
              alt="Team Photo"
              srcset=""
            />

            <span className="font-bold text-sm sm:text-2xls text-primary leading-none">
              {item.name}
            </span>
            <span className="m-0 p-0 text-sm">{item.description}</span>
          </div>
        ))}
      </div>
      <div className="text-center mt-8 invisible">
        <Button onClick={(e) => loadMore()} size="medium">
          Load {more ? 'More' : 'Less'}
        </Button>
      </div>
    </div>
  )
}

Team.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  container: PropTypes.string,
}

Team.defaultProps = {
  children: null,
  className: '',
  container: 'md',
}

export default Team
