import classnames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import PropTypes from "../../utils/PropTypes";
import CustomImage from "../CustomImage/index";

const Values = ({ className, container }) => {
  const data = useStaticQuery(graphql`
    query {
      values: allValuesYaml(limit: 10) {
        nodes {
          id
          name
          description
          image {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1.5)
            }
          }
        }
      }
    }
  `);

  const values = data.values.nodes;
  return (
    <div className={classnames("", className)}>
      <div className="flex flex-wrap justify-center items-center w-full">
        {values.map((item, index) => (
          <div key={"card_"+index}
            className={`flex flex-col  justify-center flex-wrap mx:4 base-card ${
              index === 1 && `dashed-border`
            }`}>
            <div className="flex items-center justify-center w-24 h-24 p-6 mb-8 rounded-full bg-blue-400">
              <CustomImage
                className="w-12 "
                image={item.image}
                alt="Value Photo"
                srcset=""
              />
            </div>
            <span className="font-display font-semibold text-2xl mb-4 text-secodary_color">
              {item.name}
            </span>
            <ul className="font-display list-disc font-light text-lg ">
              {item.description.split(",").map((desc, liIndex) => (
                <li key={"desc_"+liIndex} className="list-outside ml-4">
                  {desc}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

Values.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  container: PropTypes.string,
};

Values.defaultProps = {
  children: null,
  className: "",
  container: "md",
};

export default Values;
