import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import PropTypes from "../../utils/PropTypes";

const Image = ({ image, alt, className }) => (
  <div className={`relative ${className}`}>
    <GatsbyImage
      image={getImage(image)}
      imgClassName="opacity-50 !object-contain object-bottom"
      className="h-full"
      alt={alt}
    />
  </div>
);

Image.propTypes = {
  image: PropTypes.object,
  alt: PropTypes.string,
};

Image.defaultProps = {
  image: null,
  alt: "",
};

export default Image;
